

:root {
  --bs-blue-darker: #000e40;
  --max-container: 1200px;

  --bg-navbar: light-dark(var(--bs-white), var(--bs-gray-900)) !important;
  --text-navbar: light-dark(var(--bs-gray-800), var(--bs-gray-100)) !important;

  --bg-footer: light-dark(var(--bs-gray-100), var(--bs-gray-900)) !important;
  --text-footer: light-dark(var(--bs-gray-600), var(--bs-gray-400)) !important;

}

.bghero_01 {
    background-color: var(--bs-blue-darker);	
}

@media screen and (min-width: 768px) {
  .bghero_01 {
    background-image: url('../img/ia/bgcorp.jpg');
  }
}

.hovbgYellow:hover .cYellow {
  color: var(--bs-blue) !important;
}

.hovbgYellow:hover .cWhite {
  color: var(--bs-gray-800) !important;
}

.hovBgContrastFlow:hover {
  background-color: var(--bg-contrast-soft);
}

.hovbgBlue:hover .cBlue {
  color: var(--bs-white) !important;
}

.hovbgBlue:hover .cGray800 {
  color: var(--bs-yellow) !important;
}
