.bw-section-cards {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.cardboxMain  {
  font-family: var(--font-regular);
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cardboxMain .card {
  padding: 0.8em;
  border: none;
  border-radius: 5px;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cardboxMain .card-header {
  font-family: var(--font-title);
  background-color: transparent;
  border-bottom: none;
}

.cardboxMain .card-title,
.cardboxMain .card-subtitle,
.cardboxMain  .card-body{
  margin-top: 4px !important;
  margin-bottom: 4px !important;

}

.cardboxMain .card-title {
  font-family: var(--font-title);
}

.cardBoxDark {
  background-color: var(--bg-overlay-dark);
  color: var(--bs-gray-100);
}

.cardBoxLight {
  background-color: var(--bg-overlay-light);
  color: var(--bs-gray-800);
}

@media screen and (max-width: 1100px) {

  .cardboxMain {
    width: 95% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}

@media screen and (max-width: 768px) {

  .cardboxMain {
    font-size: 0.9em;
  }

.cardboxMain .card {
  padding: 0.4em;
}

}
