.faq {

  &__container {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
  }

  &__title {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__item {
    padding: 20px;
    border: 1px solid #751f1f;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f5f5f5;
    }

    &--active {
      background-color: #f5f5f5;
    }

    &-title {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    &-content {
      font-size: 1.2rem;
    }
  }

}