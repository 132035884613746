nav .navbar100 {
  /* position: fixed;
  bottom: 0; */
  width: 100% !important;

}

.navbar100 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-navbar);
  color: var(--text-navbar);
  padding: 10px !important;
  /* background-color: var(--bg-navbar);
  color: var(--text-navbar);
  width: 100% !important;
  padding: 20px; */
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  max-width: var(--max-container);

  top: 0;
  margin: auto;
}

.navbarLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.navbarLogo img {
  width: 100%;
  max-width: 250px;
  height: auto;
  padding: 5px;
  object-fit: cover;
}

/* .logoTXT {
  width: 100%;
  max-width: 300px;
} */

.logoXS  {
  min-height: 70px;
  max-height: 80px;
  width: auto;
}

.navbarMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
}

.navbarMenu > .dropdown > button {
  background-color: var(--bg-menu) !important;
  color: var(--text-menu) !important  ;
  border: none;
  padding: 5px 10px;
  margin: 0 3px;
  cursor: pointer;
}

#dropdown-menu-navbar-toggle {
  background-color: transparent !important;
}

.navbarMenu > .dropdown > .dropdown-menu {
  background-color: var(--bg-menu) !important;
  color: var(--text-menu);
}

.navbarMenu > .dropdown > .dropdown-menu a {
  color: var(--text-menu) !important;
}

.navbarMenu > .dropdown > .dropdown-menu a:hover {
  background-color: var(--bg-menu-hover) !important;
  color: var(--text-menu-hover) !important;
}

#navbarDropdownToggle {
  background-color: transparent !important;
}

.spMenuText {
  margin-left: 5px;
  padding: 5px;
  font-size: 0.9em;
}

/* @media screen and (max-width: 768px) {
  .navbarLogo {
    height: 80px;
  }
  .navbarLogo img {
    width: 250px;
  }
} */

/* .habita-nav {
  background-color: var(--bg-navbar);
  color: var(--text-navbar);
  padding: 10px 10px 20px;
} */

/* .nav-logo-container {
  width: 250px;
  min-width: 60px;
  max-width: 30%;
} */

/* .navUL {
  list-style: none;
  margin: auto;
}

.navUL,
li {
  list-style-type: none;
}

.navUL li {
  padding-left: 2em;
  text-indent: -2em;
  margin-left: 10px;
} */

.spNavMenuIcon {
  margin-right: 5px;
}

.imgsvg {
  height: 1em !important;
}

.menuSectionCard {
  width: 15%;
  min-width: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 700ms;
}

.menuSectionCard > span,
.menuSectionCardDropdown > span {
  font-size: 0.9em;
  padding-top: 10px;
  font-weight: 300;
  font-family: Mulish, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.menuSectionCardDropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 0.5em 0.7em;
  font-size: 0.9em;
}

.menuSectionCardDropdown:hover {
  background-color: var(--bg-menu-hover);
  color: var(--text-menu-hover);
  font-size: 1em;
  cursor: pointer;
}

.menuSectionCardDropdown > span {
  margin-left: 10px;
}

.menuSectionCard:hover {
  transform: scale3d(1.1, 1.1, 1.1);
}

.menuSectionCard > .hc-title {
  font-weight: 300;
  font-size: 0.7em;
  font-family: Mulish, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.menuSectionCard > img {
  width: 100px;
  max-width: 100%;
}

.menuSectionCardDropdown > img {
  width: 30px !important;
}

@media (max-width: 625px) {
  .navbarLogo > .navLogo.logoTXT {
    display: none;
  }
}

@media (min-width: 626px) {
  .navbarLogo > .navLogo.logoXS {
    display: none;
  }
}
