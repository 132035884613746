.bio, .bio > a {
  background-color:rgb(5, 0, 71);
  color: var(--bs-gray-100) !important;
}

.cont-400 {
  max-width: 400px;
  padding:  15px;
}

.cont-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.line {
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
}

.bordered {
  border: 0.7px solid #00288f;
  border-radius: 0.6em;
  margin: 0.4em;
  transition: 0.5s;
}

.bordered:hover {
  border: 0.7px solid var(--w-link-color);
  border-radius: 0.6em;
  padding: 0.8em;
}

.w-btn-hover.w-btn-hover:hover {
  transform: scale(1.2) !important;
  color: var(--w-link-color);
}